<template>
  <div class="dashboard">
    <v-container
      style='
        height: 100vh;
        background: rgb(10,7,59);
        background: linear-gradient(326deg, rgba(10,7,59,1) 0%, rgba(24,32,184,1) 100%);
      '
    >
      <v-row style="margin-top: 100px;" v-if="this.loading">
        <v-col class="d-flex flex-row align-center justify-center text-center" style="margin-top: 50px;">
          <h2>
            You have been timed out due to inactivity!
          </h2>  
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex flex-column align-center justify-center" style="margin-top: 100px;">
          <!-- <img  v-if="this.showMsg" src="/tick.svg" width="150px" class="d-flex flex-row align-center justify-center" alt=""> -->
          <img src="/close.svg" width="150px" alt="">
          <h1 class="amber--text">You were timed out</h1>
          <h4 class="d-flex flex-row align-center justify-center white--text" style="width: 350px; margin-top: 10px; text-align: center">Please contact an administrator to finish your assessment. You may close this window.</h4>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  data: () => ({
    showMsg: false,
    loader: false,
    instanceId: '',
  }),  
  components: {
  },
  mounted(){
    this.instanceId = this.$route.params.id
    this.loader = true;
  }, 
  methods: {
  
  }
}
</script>
